import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { RecaptchaVerifier } from "firebase/auth";
import { auth } from '../firebase';
import VerificationInput from "react-verification-input";
import { Card } from 'primereact/card';
import { InputNumber } from 'primereact/inputnumber';
import { Input, Page, Button } from '@mobiscroll/react';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import LoadingOverlay from './LoadingOverlay';
import mobiscroll from '@mobiscroll/react4';

const Signin = () => {
  const { signInWithPhone, user, loginWithConfirmationCode, showConfirmation, setShowConfirmation } = UserAuth();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirmationResult, setConfirmationResult] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // State for loading overlay
  const navigate = useNavigate();
  var confirmationPromise;

  useEffect(() => {
    console.log('in  the mofuckin user effectbitches')
    if (user) return navigate('/home');
  }, [user]);

  function setupRecaptcha() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        'size': 'invisible',
        'callback': (response) => {
          this.onSignInSubmit();
        }
      }, auth);
    }
  }

  function onSignInSubmit() {
    setupRecaptcha();
    const fullPhoneNumber = '+1' + phoneNumber;
    var appVerifier = window.recaptchaVerifier;
 
    confirmationPromise = signInWithPhone(fullPhoneNumber, appVerifier);
    if(confirmationPromise){
         setLoading(true); // Show loading overlay
      confirmationPromise.then((confirmationRes) => {
      setLoading(false); // Hide loading overlay
      if (confirmationRes !== 0) {
        setConfirmationResult(confirmationRes);
        setShowConfirmation(true);
      }
    }).catch((error) => {
      setLoading(false); // Hide loading overlay
      setError(error.message);
    });
  }
  }

  const changePhoneNumber = (e) => {
    if (e.value) {
      let phoneNum = e.value.toString();
      setPhoneNumber(phoneNum);
    }
  }
  const signInWithConfirmationCode = async(e)=>{
 
    try{
      console.log('confirmation code = ' + e);
      await loginWithConfirmationCode(confirmationResult, e, phoneNumber)
      
    
    }catch(e){
      console.log('we done goofed')
      setError(e.message);
      console.log(e.message);
    }
  
  
  }
  const handleSubmit = async (e) => {
    console.log('WE ARE SIGING IN SUBMITT BITCHESS!!')
    e.preventDefault();
    setError('');
    try {
      await onSignInSubmit();
    } catch (e) {
      setError(e.message);
    }
  }

  const cancelSignIn = () => {
    setShowConfirmation(false);
  }

  return (
    <Page>
      <LoadingOverlay show={loading} /> {/* Include the loading overlay */}
      <div className="mbsc-grid mbsc-justify-content-center" style={{ padding: "0" }}>
        <div className="mbsc-col-sm-12 mbsc-col-lg-6 mbsc-offset-lg-3">
          <Card style={{ paddingLeft: '1em', paddingRight: '1em' }}>
            <mobiscroll.CardHeader>
              <mobiscroll.CardTitle style={{ textAlign: 'center', fontSize: '30px' }}>Alianza Technologies</mobiscroll.CardTitle>
              {showConfirmation ? (
                <mobiscroll.CardSubtitle style={{ textAlign: 'center', fontSize: '15px' }}>Sign up for Alianza Technologies</mobiscroll.CardSubtitle>
              ) : (<div></div>)}
            </mobiscroll.CardHeader>
            <div className="mbsc-form-group">
              {!showConfirmation ? (
                <React.Fragment>
                  <div className="p-inputgroup" style={{ paddingBottom: "1em" }}>
                    <span className="p-inputgroup-addon" style={{ width: "40%" }}>Phone Number:</span>
                    <InputNumber useGrouping={false} onValueChange={(e) => changePhoneNumber(e)} />
                  </div>
                  <Button id="send-text-button" color="primary" onClick={handleSubmit} style={{ paddingBottom: "1em", paddingTop: "1em" }}>Sign In</Button>
                  <a href='https://geared.tech/login'>Looking for office login?</a>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon" style={{ width: "60% !important" }}>Confirmation Code:</span>
                    <VerificationInput id="confirmationCode" autoFocus={true} onComplete={(e) => signInWithConfirmationCode(e)} onChange={(e) => setConfirmationCode(e)} validChars="0-9" inputProps={{ inputMode: "numeric", autoComplete: "one-time-code" }} />
                  </div>
                  <Button id="cancel-sign-in-button" color="primary" onClick={cancelSignIn} style={{ paddingBottom: "1em", paddingTop: "1em" }}>Cancel</Button>
                </React.Fragment>
              )}
            </div>
          </Card>
        </div>
      </div>
    </Page>
  );
}

export default Signin;
