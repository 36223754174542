import React from 'react';
import './LoadingOverlay.css';

const LoadingOverlay = ({ show }) => {
  return (
    show && (
      <div className="loading-overlay">
        <div className="spinner"></div>
      </div>
    )
  );
};

export default LoadingOverlay;