import React, { useState } from 'react';
import Signin from './components/Signin';
import Signup from './components/Signup';

import { Route, Routes } from 'react-router-dom';
import { AuthContextProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import  {PaperlessContextProvider} from './components/Paperless/PaperlessContext';
import PaperlessParent from './components/Paperless/PaperlessParent';
import PaperlessHome from './components/Paperless/PaperlessHome';


function App(props) {
  const Sentry = props.Sentry;
console.log('Sentry ==== ', Sentry);
  return (
    <div >

 <AuthContextProvider Sentry={Sentry} >
   <Routes> 
      <Route path='/' element={<Signin />}></Route>
    </Routes>
    < PaperlessContextProvider >
      <Routes>
        <Route path='/home' element={<ProtectedRoute><PaperlessHome  /></ProtectedRoute>} />
      </Routes>
      <PaperlessParent  />
    </PaperlessContextProvider>
    
  <div id="recaptcha-container"></div>
 </AuthContextProvider >
    </div>
  );
}

export default App;
 